import { ApiCommonSettingsFeatures } from '@temperworks/types'
import temperCountries from '../composables/temperCountries'
import { ApiSettingsExternalResources } from '../interfaces/api/Settings'
import countrySpecificRoutesConfig from './../config/countrySpecificRoutes'

export const useCountryStore = defineStore('country-store', () => {
  const cookie = document.cookie.match(RegExp('(?:^|;\\s*)' + 'country' + '=([^;]*)'))
  const countryArray = temperCountries().temperCountries
  const country = ref<string>('')
  const countryAlpha3 = ref<string>('')
  const countryAlpha2 = ref<string>('')
  const backend = ref<string>('')
  const currency = ref<string>('')
  const locale = ref<string>('')
  const externalResources = ref<ApiSettingsExternalResources>()
  const nuxtApp = useNuxtApp()
  const browserLang = navigator.language
  const changeCountry = ref<boolean>(false)
  const features = ref<ApiCommonSettingsFeatures>()
  const countrySpecificRoutes = ref<object>({})
  const availableLocales = ref<string[]>([])

  if (cookie) {
    const countryCheck = countryArray.find(countryCheck => countryCheck.country === cookie[1])

    if (countryCheck) {
      country.value = countryCheck.country
      backend.value = countryCheck.backend
      currency.value = countryCheck.currency
      locale.value = countryCheck.i18nLocale
      nuxtApp.$i18n.setLocale(countryCheck.locale)
    }
  } else if (browserLang.toLowerCase().includes('gb')) {
    const hardCoded = countryArray.find(countryCheck => countryCheck.country === 'uk')
    country.value = hardCoded!.country
    backend.value = hardCoded!.backend
    currency.value = hardCoded!.currency
    locale.value = hardCoded!.i18nLocale
    nuxtApp.$i18n.setLocale(hardCoded!.locale)
  } else if (browserLang.toLowerCase().includes('fr')) {
    const hardCoded = countryArray.find(countryCheck => countryCheck.country === 'fr')
    country.value = hardCoded!.country
    backend.value = hardCoded!.backend
    currency.value = hardCoded!.currency
    locale.value = hardCoded!.i18nLocale
    nuxtApp.$i18n.setLocale(hardCoded!.locale)
  } else {
    const hardCoded = countryArray.find(countryCheck => countryCheck.country === 'nl')
    country.value = hardCoded!.country
    backend.value = hardCoded!.backend
    currency.value = hardCoded!.currency
    locale.value = hardCoded!.i18nLocale
    nuxtApp.$i18n.setLocale(hardCoded!.locale)
  }
  nuxtApp.$i18n.setLocale('en_EN')

  function setCountry(name: any) {
    country.value = name
    countrySpecificRoutes.value = countrySpecificRoutesConfig[name]
  }
  function setBackend(url: string) {
    backend.value = url
  }
  function setCurrency(value: string) {
    currency.value = value
  }
  function setCountryChange() {
    changeCountry.value = !changeCountry.value
  }
  function setLocale(value: string) {
    locale.value = value
  }

  function getCountrySettings() {
    return new Promise((resolve, reject) => {
      if (backend.value) {
        $fetch(backend.value + '/api/v4/settings/?include%5B%5D=routes&include%5B%5D=features', {
          method: 'GET'
        })
          .then((response: any) => {
            currency.value = response.currency_code
            countryAlpha3.value = response.country_code_iso_alpha3
            countryAlpha2.value = response.country_code_iso_alpha2
            availableLocales.value = response.available_locales
            countrySpecificRoutes.value = countrySpecificRoutesConfig[country.value]
            const externalResourcesResponse = response.external_resources
            Object.keys(externalResourcesResponse).forEach((key: string) => {
              externalResourcesResponse[key] =
                externalResourcesResponse[key]
                  ? externalResourcesResponse[key].replace('{language}', countryAlpha2.value).toLowerCase()
                  : externalResourcesResponse[key]
            })
            externalResources.value = externalResourcesResponse
            features.value = response.features
            resolve(response)
          })
          .catch(err => reject(err))
      } else {
        resolve(false)
      }
    })
  }

  return {
    country,
    countryAlpha3,
    countryAlpha2,
    externalResources,
    backend,
    changeCountry,
    currency,
    locale,
    setCountry,
    setBackend,
    setCountryChange,
    getCountrySettings,
    setCurrency,
    setLocale,
    features,
    countrySpecificRoutes,
    availableLocales
  }
})
