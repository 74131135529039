import { ValidatorMap } from '@temperworks/types'

const validateRequiredByType = {
  number: (value: number) => !!value || value === 0,
  string: (value: string) => !!value && value.length > 0,
  array: (value: Array<any>) => !!value && value.length > 0,
  object: (value: Record<any, any>) => !!value && Object.keys(value).length > 0,
  undefined: () => false
}

const validators: ValidatorMap = {
  required: (value: any) => {
    return validateRequiredByType[(Array.isArray(value) ? 'array' : String(typeof value))](value)
  },
  min: (value: string | Array<any>, len: number) => value && value.length >= len,
  max: (value: string | Array<any>, len: number) => value.length <= len,
  email: (value: string) => /^[a-zA-Z0-9._+-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/.test(value),
  hasSymbols: (value: string) => /.*["#$%&'()*+,-./!:;<=>?@[\]^_`{|}~]+.*/.test(value),
  hasDigits: (value: string) => /.*\d+.*/.test(value),
  includes: (value: string, arg: string) => value.includes(arg),
  isTrue: (_value: string, arg: string) => arg === 'true',
  noFutureDate: (value: string) => {
    if (!value) return true
    const date = new Date(value)
    const today = new Date()
    return date <= today
  },
  noSameDate: (value: string, arg: string) => {
    if (!value) return true
    const date = new Date(value)
    const compareDate = new Date(arg)
    return date.getTime() !== compareDate.getTime()
  }
}

export default validators
