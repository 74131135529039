import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45set_45user_45global from "/opt/build/repo/apps/spa/middleware/1.setUser.global.ts";
import _2_45toc_45global from "/opt/build/repo/apps/spa/middleware/2.TOC.global.ts";
import mixpanel_45global from "/opt/build/repo/apps/spa/middleware/mixpanel.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45set_45user_45global,
  _2_45toc_45global,
  mixpanel_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/node_modules/.pnpm/@nuxt-alt+auth@2.7.6_nuxi@3.11.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vue-tsc@1.8.27_ty_q73bv4uhapzucfro4ldj4gwfru/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs"),
  "is-client": () => import("/opt/build/repo/apps/spa/middleware/isClient.ts"),
  "is-freeflexer": () => import("/opt/build/repo/apps/spa/middleware/isFreeflexer.ts"),
  "official-rep": () => import("/opt/build/repo/apps/spa/middleware/officialRep.ts"),
  onboarding: () => import("/opt/build/repo/apps/spa/middleware/onboarding.ts"),
  unauth: () => import("/opt/build/repo/apps/spa/middleware/unauth.ts")
}