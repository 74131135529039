import * as Sentry from '@sentry/vue'

export default {
  install: (app, { router, sentryDsn, enableSentry }: { router, sentryDsn: string, enableSentry: boolean }) => {
    if (enableSentry) {
      Sentry.init({
        app,
        dsn: sentryDsn,
        sampleRate: 1,
        tracesSampleRate: 1.0,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          Sentry.replayIntegration(),
        ],
      })
      app.provide('sentry', Sentry)
    }
  }
}
